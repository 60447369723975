import * as React from 'react';
import Button from '../../components/Button.js';
import Typography from '../../components/Typography.js';
import AboutHeroLayout from './AboutHeroLayout.js';
import backgroundImage from '../../images/background/library.jpg';

export default function ProductHero() {
  return (
    <AboutHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    />

  );
}
