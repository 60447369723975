import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.min.css';

//Home Elements
import Home from './homePageElements/Home.js';
import AppAppBar from './AppAppBar.js';
import Footer from './AppFooter.js';

//About Elements
import VisionHome from './aboutPageElements/Vision/VisionHome.js';
import PastorHome from './aboutPageElements/Pastor/PastorHome.js';
import BeliefsHome from './aboutPageElements/Beliefs/BeliefsHome.js';
import YouthHome from './aboutPageElements/Youth/YouthHome.js';
import HistoryHome from './aboutPageElements/History/HistoryHome.js';
import MinistriesHome from './aboutPageElements/Ministries/MinistriesHome.js';

//Contact Elements
import ContactHome from './contact/ContactHome.js';

function App() {
  
  useEffect(() => {
    document.title = 'Nazarene Baptist Chruch'; 
  }, []);

  // Redirect Component for external URL
  const RedirectToExternal = ({ url }) => {
    useEffect(() => {
      // Redirect to the external website
      window.location.href = url;
    }, [url]);

    return null; // No UI is rendered
  };

  return (
    
    <Router scrollRestoration="top">
      <AppAppBar />
      <Routes>
        {/* Home Routes */}
        <Route path="" element={<Home />} />
        <Route path="/" element={<Home />} />

        {/* About Routes */}
        <Route path="/our-vision" element={<VisionHome />} />
        <Route path="/our-pastor" element={<PastorHome />} />
        <Route path="/our-beliefs" element={<BeliefsHome />} />
        <Route path="/our-youth" element={<YouthHome />} />
        <Route path="/our-history" element={<HistoryHome />} />
        <Route path="/our-ministries" element={<MinistriesHome />} />

        {/* Resources Routes */}
        {/*<Route path="/prayer-request" element={<Home />} />
        <Route path="/praise-reports" element={<Home />} />
        <Route path="/emessages" element={<Home />} />
        <Route path="/speak-request" element={<Home />} />
        <Route path="/member-services" element={<Home />} />*/}
        <Route
          path="/giving"
          element={<RedirectToExternal url="https://tithe.ly/give/?c=1063424&k=1" />} // External URL
        />
        

        {/* Events Routes */}
        {/*<Route path="/calender" element={<Home />} />
        <Route path="/evites" element={<Home />} />*/}

        {/*Contact Routes */}
        <Route path="/contact" element={<ContactHome />} />

      </Routes>
      {/*<Newsletter/>*/}
      <Footer/>
    </Router>
  );
}

export default App;