import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `3px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .08)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '3px solid rgba(0, 0, 0, .125)',
}));

export default function AccordionExpandDefault() {

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion 
        defaultExpanded 
        expanded={expanded === 'panel1'} 
        onChange={handleChange('panel1')}
        sx={{
          
        }}
      >
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>The Scriptures</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that "all Scripture is given by inspiration of God." (2 Timothy 3:16). We understand this to mean that the whole Bible is inspired in that holy men of God "were moved by the Holy Spirit" (2 Peter 1:21) to write the very words of Scripture.
          </Typography>
          <Typography sx={{mt:2}}>
          We believe that this divine inspiration extends equally and fully to all parts of Scripture as it appeared in the original manuscripts.
          </Typography>
          <Typography sx={{mt:2}}>
          We believe that the whole Bible in the originals is therefore without error. We believe that all the Scriptures center around the Lord Jesus Christ in His person and work, in His first and second coming, and hence that no portion, even of the Old Testament, is properly read, or understood, until it leads to Him.
          </Typography>
          <Typography sx={{mt:2}}>
          We also believe that all Scripture was designed for our practical instruction. (Luke 24:27, 44; John 5:39; Rom. 15:4; 1 Cor. 10:11; 2 Tim. 3:16)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>The Triune God</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that there is only one true God. He is revealed to us as Father, Son and Holy Spirit, each with distinct personal attributes, but without division of nature. (John 4:23-24; Matt. 28:19)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>God the Father</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          God, as Father, reigns over His entire universe with providential care.
            He is all-powerful, all loving, all knowing and all wise.
          </Typography>
          <Typography sx={{mt:2}}>
          He is fatherly in attitude toward all men but is Father, indeed, to those who have become children of God through Jesus Christ, Who will deliver them into the Father's hands. (Gen. 1:1; 1 Chron. 29:10; Jer. 10:10, Matt. 6:9; Acts 1:7; Rom. 8:14, 15; 1 Cor. 8:6; 1 Cor. 15:24; Eph.4:6)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>God the Son</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that the Lord Jesus Christ, the eternal Son of God, became man without ceasing to be God, having been conceived of the Holy Spirit and born of a virgin, in order that He might reveal God and redeem sinful man.
          </Typography>
          <Typography sx={{mt:2}}>
          This redemption He accomplished by voluntarily giving Himself as a sinless, substitutionary sacrifice on the cross, thereby satisfying God's righteous judgment against sin. After substantiating the accomplishment of that redemption/justification by His bodily resurrection from the grave, He ascended to the right hand of His Father where He intercedes on behalf of those who trust Him. (Luke 1:34; John 1:1, 2, 14, 18; Rom. 3:24-26, 8:34)
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>God the Holy Spirit</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that the Holy Spirit is the Divine Person who convicts each person of sin (righteousness and judgment), that He alone brings new life to those who are spiritually dead; that He baptizes (or places) all believers into the one true church, which is the Body of Christ; that He indwells them permanently, seals them unto the day of final redemption, bestows spiritual gifts upon them, fills (controls) those who are yielding to Him.
          </Typography>
          <Typography sx={{mt:2}}>
          Every believer is called to live so in the power of the indwelling Spirit that he will not fulfill the lust of the flesh but will bear fruit to the glory of God. (John 3:3-8, 14:16, 17, 16:7-11; 1Cor. 12:7-11, 13; Eph. 4:30, 5:18)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Creation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that man was created directly by and in the image of God. We believe that God created the heavens and the earth, including all life, by direct act. (Gen. 1:1; John 1:3; Col. 1:16, 17)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>The Fall of Man</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Although man was created in the image of God, he fell into sin and is lost; this is true of all men, and except a man be born again by the Holy Spirit, he cannot see the kingdom of God. (Gen. 1:26, 27; John 3:3; Rom. 3:10, 23)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Atonement for Sin</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that the Lord Jesus Christ died for our sins according to the Scriptures as a substitutionary sacrifice, and that all who believe on Him are redeemed by His shed blood. We believe in the resurrection of the crucified body, of our Lord Jesus Christ and in His ascension into heaven; He is our High Priest and Advocate. (John 1:1-3, 14; 3:1-7, 16; Heb. 10:4-14; 1 John 2:2)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Salvation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that salvation is the gift of God's grace.
            It cannot be gained or made more secure by meritorious works, but is freely bestowed upon all who put their faith in the finished work of Jesus Christ at Calvary.
          </Typography>
          <Typography sx={{mt:2}}>
          All who so trust the Savior are forgiven of their sins and born into the family of God by the regenerating work of the Holy Spirit. (John 1:12; Acts 16:30-33; Rom. 10:9,10; Eph. 1:7, 2:8-9)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>The Great Commission</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Until the return of Christ, it is the Christian's duty and privilege to seek the fulfillment of Christ's Great Commission and to minister in His name to a needy world.
          </Typography>
          <Typography sx={{mt:2}}>
          We are to be instruments of Jesus Christ as the Holy Spirit minister's redemption and reconciliation in the world. (Matthew 25:31-46; 28:18-20)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>The Church</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that a visible church of Christ is a congregation of baptized believers associated by covenant in the faith and fellowship of the gospel.
          </Typography>
          <Typography sx={{mt:2}}>
          We observe the ordinances of Christand are governed by His Word, and exercise our gifts. (Acts 2:41-42)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>The Walk of a Christian</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that we are called with a holy calling, to walk not after the flesh, but after the Spirit, and so to live in the power of the indwelling Spirit that we will not fulfill the lust of the flesh. But the flesh is never eradicated in this life and the individual chooses to keep the flesh in subjection to Jesus Christ through the power of the Holy Spirit or it will surely manifest its presence in our lives to the dishonor of our Lord. (Rom. 6:11-13; 8:2, 4, 12, 13; Gal. 5:16-23; Eph. 4:22-24; Col. 2:1-10; 1 Pet. 1:14-16; 1 John 1:4-7: 3:5-9)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Baptism and the Lord's Supper</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We believe that Christian baptism is the immersion in water of a believer. (Acts 2:41)
          </Typography>
          <Typography sx={{mt:2}}>
          The Lord's Supper is the commemoration of the death of the Lord Jesus, preceded by solemn self-examination. (1 Corinthians 11: 23-31)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>The Resurrection</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We believe that the Scriptures clearly teach that Jesus rose from the dead.
            He now exists in His glorified body at God's right hand.
            There will be a resurrection of the righteous and the wicked.
          </Typography>
          <Typography sx={{mt:2}}>
          The bodies of the righteous will conform to the glorious spiritual body of Jesus Christ. (1 Thessalonians 4:16-17)
          </Typography>
        </AccordionDetails>
        </Accordion>
      
    </div>
  );
}
