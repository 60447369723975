import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, LoadScript, useLoadScript } from "@react-google-maps/api";
import {APIProvider, Map, AdvancedMarker} from '@vis.gl/react-google-maps';
import {MarkerF} from '@react-google-maps/api'
import config from '../config.js';
import axios from 'axios';


function LocationMap() {
  const [location, setLocation] = useState({lat: 53.54992, lng: 10.00678});
  //const [error, setError] = useState(null);

  // Determine the environment (e.g., development or production)
  const environment = process.env.NODE_ENV || 'development';

  // Set the base URL for Axios requests
  const api = axios.create({
  baseURL:  config[environment].apiUrl.replace('/api', ''),
  });


  useEffect(() => {
    
    api.get('/api/getLocation')
    .then((response) => {
      //console.log("Retrieved Location Data:", response.data);
      setLocation(response.data);

    })
    .catch((error) => {
      console.error("Axios Get Location Error:", error);
    });


  }, []);

  const mapContainerStyle = {
    width: '100%',
    height: '75vh',
  };

  // Render the map or an error message
  return (
    <div>
      {!location ? (
        <h1>Loading Map...</h1>
      ) : (
        <LoadScript async googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={mapContainerStyle} center={{lat: location.lat, lng: location.lng}} zoom={17}>
                <MarkerF position={{lat: location.lat, lng: location.lng}} />
            </GoogleMap>
        </LoadScript>

      )}
    </div>
  );
}

export default LocationMap;
