import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import ChurchIcon from '@mui/icons-material/Church';
import GroupsIcon from '@mui/icons-material/Groups';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export default function FourCards() {
  return (
    <Grid container spacing={3} sx={{mb: 5}}>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Card elevation={10} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} 
          sx={{ml: 4, mr: {xs: 4, sm: 0} }}
          >
          <CardContent>
            <Typography variant="h5" component="div" align="center" sx={{mb: 0}}>
                <ChurchIcon sx={{fontSize: {xs: '2.5rem', sm: '2.5rem', md: '3rem', lg: '3rem'} }}/> 
            </Typography>
            <Typography variant="h5" component="div" align="center" sx={{mb: 1,
            fontSize: {
              xs: '1.25rem',
              sm: '1.25rem',
              md: '1.5rem',
              lg: '2rem',
            }, 
            }}>
              We Value the Word of God
            </Typography>
            <Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'center' }} sx={{mb: 1,
            fontSize: {
              xs: '1.2rem',
              sm: '1.1rem',
              md: '1.25rem',
              lg: '1.25rem',
            }, 
            }}>
              "For the Priests lips should keep knowledge; for he is the messenger of the Lord of Hosts"
            </Typography>
            <Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'right' }}
            sx={{
              fontSize: {
                xs: '1.2rem',
                sm: '1rem',
                md: '1.25rem',
                lg: '1.25rem',
              }, 
            }}
            >
              - Luke 6:46 - 49
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Card elevation={10} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} 
          sx={{ ml: {xs: 4, sm: 0}, mr: {xs: 4, sm: 4, md: 4, lg: 4}}}
        >
          <CardContent>
            <Typography variant="h5" component="div" align="center" sx={{mb: 0}}>
                <GroupsIcon sx={{fontSize: {xs: '2.5rem', sm: '2.5rem', md: '3rem', lg: '3rem'} }}/> 
            </Typography>
            <Typography variant="h5" component="div" align="center" sx={{mb: 1, 
            fontSize: {
              xs: '1.25rem',
              sm: '1.25rem',
              md: '1.5rem',
              lg: '2rem',
            }, 
            }}>
              We Value Teamwork
            </Typography>
            <Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'center' }} sx={{mb: 1,
            fontSize: {
              xs: '1.2rem',
              sm: '1.1rem',
              md: '1.25rem',
              lg: '1.25rem',
            }, 
            }}>
              "We value teamwork and believe we can achieve greater results for the Kingdom of God through partnerships, collaboration and effective communication."
            </Typography>
            <Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'right' }}
            sx={{
              fontSize: {
                xs: '1.1rem',
                sm: '1rem',
                md: '1.25rem',
                lg: '1.25rem',
              }, 
            }}
            >
              - Exodus 17:8 - 14
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Card elevation={10} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} 
          sx={{mr: {xs: 4, sm: 0}, ml: {xs: 4, sm: 4, md: 4, lg: 4}}}
        >
          <CardContent>
            <Typography variant="h5" component="div" align="center" sx={{mb: 0}}>
                <Diversity1Icon sx={{fontSize: {xs: '2.5rem', sm: '2.5rem', md: '3rem', lg: '3rem'} }}/> 
            </Typography>
            <Typography variant="h5" component="div" align="center" sx={{mb: 1, 
              fontSize: {
                xs: '1.25rem',
                sm: '1.25rem',
                md: '1.5rem',
                lg: '2rem',
              }, 
            }}>
              We Value People
            </Typography>
            <Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'center' }} sx={{mb: 1,
              fontSize: {
                xs: '1.2rem',
                sm: '1.1rem',
                md: '1.25rem',
                lg: '1.25rem',
              }, 
            }}>
              "We value people and hold to the belief that every person (without exception) should be treated with the highest degree of love, care, dignity, respect, and honor."
            </Typography>
            <Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'right' }}
              sx={{
                fontSize: {
                  xs: '1.25rem',
                  sm: '1rem',
                  md: '1.25rem',
                  lg: '1.25rem',
                }, 
              }}
            >
              - Luke 10:25 - 37
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Card elevation={10} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} 
          sx={{ml: {xs: 4, sm: 0}, mr: 4}}
        >
          <CardContent>
            <Typography variant="h5" component="div" align="center" sx={{mb: 0}}>
                <EmojiEventsIcon sx={{fontSize: {xs: '2.5rem', sm: '2.5rem', md: '3rem', lg: '3rem'} }}/> 
            </Typography>
            <Typography variant="h5" component="div" align="center" sx={{mb: 1,
            fontSize: {
              xs: '1.25rem',
              sm: '1.25rem',
              md: '1.5rem',
              lg: '2rem',
            }, 
            }}>
              We Value Excellence
            </Typography>
            <Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'center' }} sx={{mb: 1,
            fontSize: {
              xs: '1.25rem',
              sm: '1.1rem',
              md: '1.25rem',
              lg: '1.25rem',
            }, 
            }}>
              "We value excellence and believe that it is a mindset that motivates us to be and do our best."
            </Typography>
            <Typography variant="body1" style={{ fontStyle: 'italic', textAlign: 'right' }}
            sx={{
              fontSize: {
                xs: '1.25rem',
                sm: '1rem',
                md: '1.25rem',
                lg: '1.25rem',
              }, 
            }}
            >
              - Daniel 6:1 - 4
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
