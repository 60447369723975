import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography.js';
import Button from '../../components/Button.js';

import AccordionExpandDefault from './Accordian.js';

import picture from '../../images/background/churchDance3.jpg';

function MeetSkyyMyles() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 
  }, []);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#f9f9f9', mb: 0 }}
    >
      <Container 
        component="section" 
        sx={{ mt: 5, mb: 5, minHeight: 'auto'}}
      >
        <Grid container>

          {/* Right-side Container */}
          <Grid item xs={12} md={12} >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                //padding: '2rem',
                //position: 'absolute',
                zIndex: 1,
              }}
            >
              <Typography 
                variant="h1"
                component="h2"  
                gutterBottom 
                sx={{ 
                  color: 'black', 
                  textDecoration: 'underline', 
                  textAlign: 'center',
                  fontSize: {
                    xs: '3rem',
                    sm: '3.5rem',
                    md: '4rem',
                    lg: '5rem',
                  }, 
                }} 
              >
                Our Beliefs
              </Typography>
              
            </Box>
          </Grid>
          <Grid item xs={12} md={12} >
            <AccordionExpandDefault/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default MeetSkyyMyles;
