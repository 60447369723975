import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/material/styles';

// My Components
import AppBar from './components/AppBar';

// MUI Components
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden'; 
import Collapse from '@mui/material/Collapse';

// Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';


// Logo
import logo from './images/logos/chruchLogo-removebg.png';

function MobileAppBar() {
  const [scrolling, setScrolling] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openPropDrawer, setOpenPropDrawer] = React.useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handlePropDrawerClick = () => {
    setOpenPropDrawer(!openPropDrawer);
  };

 
  const drawerContent = (
    <List>
     

      <ListItemButton onClick={handlePropDrawerClick}>
        <ListItemText primary="About" />
        {openPropDrawer ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openPropDrawer} timeout="auto" unmountOnExit sx={{ml: 2}}>
        <List component="div" disablePadding>
          <ListItemButton component={Link} to={"/our-vision"} onClick={scrollToTop}>
            <ListItemText primary="Our Vision" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-pastor"} onClick={scrollToTop}>
            <ListItemText primary="Our Pastor" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-youth"} onClick={scrollToTop}>
            <ListItemText primary="Our Youth" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-beliefs"} onClick={scrollToTop}>
            <ListItemText primary="Our Beliefs" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-history"} onClick={scrollToTop}>
            <ListItemText primary="Our History" />
          </ListItemButton>
          <ListItemButton component={Link} to={"/our-ministries"} onClick={scrollToTop}>
            <ListItemText primary="Our Ministries" />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton component={Link} to={'/giving'} onClick={scrollToTop}>
        <ListItemText primary="Giving" />
      </ListItemButton>

      <ListItemButton component={Link} to={'/contact'} onClick={scrollToTop}>
        <ListItemText primary="Contact" />
      </ListItemButton>

    </List>
  );

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };  

  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
        position="fixed"
        sx={{ 
          backgroundColor: scrolling ? 'rgba(15, 15, 15, 1)' : 'black', 
          transition: 'background-color 0.5s', 
          zIndex: 1000,
          //width: "80%",
          left: 0,
          py: 1
        }}
      >
  
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            
              <IconButton href="/" sx={{ p: 1}}>
                <Avatar src={logo}
                  alt="Logo"
                  sx={{
                    width: '60px',
                    height: '60px',
                    ml: {xs: 2, sm: 0},
                    position: 'absolute',
                    //top: '15px',
                    //transform: 'translateY(-50%)',
                  }} />
              </IconButton>
             
             
              <Typography
                variant="body1"
                noWrap
                component="div"
                href="/"
                sx={{
                  alignItems: 'center', // Center vertically with the Avatar
                  fontFamily: 'Merriweather',
                  fontWeight: 300,
                  letterSpacing: '.2rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  flexGrow: 1,
                  display: 'flex', // Center horizontally
                  justifyContent: 'center', // Center horizontally
                  fontSize: { xs:'1.5rem' , sm: '1.75rem'},
                  ml: {xs: 3, sm: 0},
                }}
              >
                Nazarene Baptist
              </Typography>


          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
       
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} >
        <div style={{ width: 225 }}>
          {drawerContent}
        </div>
      </Drawer>
    </Box>
    
  );
}

export default MobileAppBar;