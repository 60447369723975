/* eslint-disable import/first */
import React, { lazy, Suspense } from 'react';
import { useState, useEffect, useRef } from 'react';

//My Components
const ProductHero = lazy(() => import('./ProductHero'));
const Meet = lazy(() => import('./Meet'));
import withRoot from './withRoot';
const Knowledge = lazy(() => import('./knowledge'));
const Latest = lazy(() => import('./latest'));
const PhotoTextRight = lazy(() => import('./PhotoTextRight'));
const PlanVisit = lazy(() => import('./PlanVisit'));
const Community = lazy(() => import('./PhotoTextLeft'));
const Newsletter = lazy(() => import('./Newsletter'));


function Index() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <React.Fragment>
      <ProductHero /> 
      <Knowledge /> {/* Three Cards */} 
      <PlanVisit/> {/* Plan Your Visit */} 
      <Latest/> {/* Latest Sermon */} 
      <Meet /> {/* Meet the Pastor */} 
      <Community/> {/* Join the Community */} 
      <Newsletter/>
    </React.Fragment>
  );
}

export default withRoot(Index);
