import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// My Components
import AppBar from './components/AppBar';
import MobileAppBar from './MobileAppBar';

// MUI Components
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden'; 

// Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import LocalSeeIcon from '@mui/icons-material/LocalSee';

// Logo
import logo from './images/logos/chruchLogo-removebg.png';

//const pages = ['About Us', 'Properties', 'Seminars', 'Mentor Program', 'Loans'];
const aboutDropDown = ['Our Vision', 'Our Pastor', 'Our Youth', 'Our Beliefs', 'Our History', 'Our Ministries']
const connectDropDown = ['Welcome', 'What to Expect']
const resourcesDropDown = ['Prayer Request', 'Praise Reports', 'eMessages', 'Speak Request', 'Member Services', 'Giving'];
const eventsDropDown = ['Calender', 'evites']

const styles = `
  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .app-bar-expanded {
    animation: slideUp 2s ease-in-out forwards;
  }

  .app-bar-collapsed {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 2s ease-in-out, opacity 2s ease-in-out;
  }
  
  .logo-container-expanded {
    animation: slideUp 2s ease-in-out forwards;
  }

  .logo-container-collapsed {
    transform: translateY(50%);
    opacity: 0;
  }
  
  .nav-links-expanded {
    animation: slideUp 2.5s ease-in-out forwards;
  }

  .nav-links-collapsed {
    transform: translateY(50%);
    opacity: 0;
  }
`;

function isTabletOriPad() {
  // Check if the User-Agent string contains keywords commonly found in tablet or iPad devices
  const userAgent = navigator.userAgent.toLowerCase();
  return /ipad|tablet|android/.test(userAgent);
}

function AppAppBar() {
  const [scrolling, setScrolling] = useState(false);
  const [openAbout, setOpenAbout] = useState(null);
  const [openResources, setOpenResources] = useState(null);
  const [openProperties, setOpenProperties] = useState(null);
  const [openLearn, setOpenLearn] = useState(null);
  const [openLoan, setOpenLoan] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [expandedLinks, setExpandedLinks] = useState(false);
  const anchorRefProp = useRef(null);
  const anchorRefRes = useRef(null);
  const anchorRefLearn = useRef(null);
  const anchorRefLoan = useRef(null);
  const anchorRefAbout = useRef(null);

  const isMobile = useMediaQuery({ maxWidth: 821 }); 
  const isMobile2 = isTabletOriPad();
  const location = useLocation();
  const isHomePage = location.pathname === '/'; // Check if the current pathname is the homepage ("/")
  const isOnAdminPage = location.pathname.includes('/admin') || location.pathname.includes('/loan-application');; 
  
  
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    //console.log("Home Page?", isHomePage);
    //console.log("Mobile?", isMobile);
    // Delay setting 'expanded' to true
    let timeoutId;

    if(isHomePage){
      timeoutId = setTimeout(() => {
          setExpanded(true);
      }, 3000); 
    

      timeoutId = setTimeout(() => {
          setExpandedLinks(true);
      }, 4000); 
    } else {
      setExpanded(true);
      setExpandedLinks(true);
    }

    // Clean up event listeners and the timeout
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  // Handle About Dropdown
  const handleMouseLeaveAbout = () => {
    setOpenAbout(false);
  };

  const handleAboutToggle = () => {
    setOpenAbout((prevOpen) => !prevOpen);
    setOpenLearn(false);
    setOpenResources(false);
    setOpenLoan(false);
    setOpenProperties(false);
  }

  const handleCloseAbout = (event) => {
    if (anchorRefProp.current && anchorRefProp.current.contains(event.target)) {
      return;
    }
    setOpenAbout(false);
  };

  function handleListKeyDownAbout(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenAbout(false);
    } else if (event.key === 'Escape') {
      setOpenAbout(false);
    }
  }

  // Handle Connect Dropdown
  const handleMouseLeaveProp = () => {
    setOpenProperties(false);
  };

  const handlePropToggle = () => {
    setOpenProperties((prevOpen) => !prevOpen);
    setOpenLearn(false);
    setOpenResources(false);
    setOpenLoan(false);
    setOpenAbout(false);
  }

  const handleCloseProp = (event) => {
    if (anchorRefProp.current && anchorRefProp.current.contains(event.target)) {
      return;
    }
    setOpenProperties(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProperties(false);
    } else if (event.key === 'Escape') {
      setOpenProperties(false);
    }
  }

  //Handle Resources Dropdown
  const handleMouseLeave = () => {
    setOpenResources(false);
  };

  function handleListKeyDownRes(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenResources(false);
    } else if (event.key === 'Escape') {
      setOpenResources(false);
    }
  }

  const handleResToggle = () => {
    setOpenResources((prevOpen) => !prevOpen);
    setOpenLearn(false);
    setOpenProperties(false);
    setOpenLoan(false);
    setOpenAbout(false);
  }

  const handleCloseRes = (event) => {
    if (anchorRefProp.current && anchorRefProp.current.contains(event.target)) {
      return;
    }
    setOpenResources(false);
  };

  // Handle Events Dropdown
  const handleMouseLeaveLearn = () => {
    setOpenLearn(false);
  };

  const handleLearnToggle = () => {
    setOpenLearn((prevOpen) => !prevOpen);
    setOpenProperties(false);
    setOpenResources(false);
    setOpenLoan(false);    
    setOpenAbout(false);
  }

  const handleCloseLearn = (event) => {
    if (anchorRefProp.current && anchorRefProp.current.contains(event.target)) {
      return;
    }
    setOpenLearn(false);
  };

  function handleListKeyDownLearn(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenLearn(false);
    } else if (event.key === 'Escape') {
      setOpenLearn(false);
    }
  }

  //Close All Dropdowns
  const closeToggels = () => {
    setOpenProperties(false);
    setOpenResources(false);
    setOpenLearn(false);
    setOpenAbout(false);
  }

  return (

    <>

    {(isMobile !== true || isMobile2 !== true) &&
    <Grid container>
    <Grid item xs={12}>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
        position="fixed"
        style={{ 
          backgroundColor: isOnAdminPage ? 'rgba(15, 15, 15, 1)' : scrolling ? 'rgba(15, 15, 15, 1)' : 'transparent', 
          transition: 'background-color 0.5s', 
          //height: '100px',
          zIndex: '1000',
        }}
        className={isHomePage ? (expanded ? 'app-bar-expanded' : 'app-bar-collapsed') : ''}
      >
        <Container maxWidth="xl" >
        
          <Toolbar
            disableGutters
            style={{
              //display: 'flex',
              //justifyContent: 'space-between',
              //alignItems: 'center',
              height: '100%', // Added height to center vertically
              width: '100%',
            }}
            //className="app-bar-element"
          >
           
            <Box 
              sx={{ display: 'flex', alignItems: 'center' }}
              className={
                isHomePage ? (expanded ? 'logo-container-expanded' : 'logo-container-collapsed') : ''
              }
              width="25%"
              justifyContent="center"
            >             
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  alignItems: 'center', // Center vertically with the Avatar
                  fontFamily: 'Merriweather',
                  fontWeight: 300,
                  letterSpacing: '.2rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  fontSize: { md: '1rem', lg: '1.1rem', xl: '1.4rem'} //resize based on screensize
                }}
              >
               
                  <Avatar src={logo}
                    alt="Logo"
                    sx={{
                      width: { md: '50px', lg: '90px', xl: '90px'},
                      height: { md: '50px', lg: '90px', xl: '90px'},
                      marginRight: '10px',
                      //position: 'absolute',
                      top: '7px',
                      //transform: 'translateY(-50%)',
                      mb: 1
                    }} 
                  />
              
                Nazarene Baptist
              </Typography>
            </Box>
           
            <Box 
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
              sx={{ flexGrow: 1, zIndex: 10, /*marginLeft: 'auto', marginRight: 'auto'*/  }}
              className={
                isHomePage ? (expandedLinks ? 'nav-links-expanded' : 'nav-links-collapsed') : ""
              } 
            >
              
                {/* About Dropdown */}
                <Button
                  key={'aboutus'}
                  component={Link} 
                  to={'/our-vision'}
                  color="inherit"
                  variant="inherit"
                  sx={{ 
                    //my: 2, 
                    //mr: 1, 
                    //ml: 1,
                    m: {md: 0, lg: 1},
                    color: 'white', 
                    //display: 'block', 
                    '&:hover': { textDecoration: 'underline' },
                    fontFamily: 'Merriweather',
                    letterSpacing: '.1rem',
                    fontSize: { md: '1rem', lg: '1.3rem'}       
                  }}
                  onMouseEnter={handleAboutToggle}
                  aria-controls={openAbout ? 'composition-menu' : undefined}
                  aria-expanded={openAbout ? 'true' : undefined}
                  aria-haspopup="true"
                  ref={anchorRefAbout}
                >
                  About
                </Button>

                <Popper
                  open={openAbout}
                  anchorEl={anchorRefAbout.current}
                  role={undefined}
                  placement="bottom"
                  transition
                  disablePortal
                  onMouseLeave={handleAboutToggle}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseAbout}>
                          <MenuList
                            autoFocusItem={openAbout}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDownAbout}
                            sx={{flexDirection: 'column'}}
                          >
                            {aboutDropDown.map((prop) => (
                              <MenuItem
                                key={prop}
                                component={Link}
                                to={`/${prop.toLowerCase().replace(/\s/g, '-')}`}
                                onClick={handleMouseLeaveAbout } // Close the menu when a resource is clicked
                                
                              >
                                {prop}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper> 

                <Button
                  key={'giving'}
                  component={Link} 
                  to={'/giving'}
                  color="inherit" 
                  sx={{ 
                    m: {md: 0, lg: 1},
                    color: 'white', 
                    display: 'block', 
                    '&:hover': { textDecoration: 'underline' },
                    fontFamily: 'Merriweather',
                    letterSpacing: '.1rem',
                    fontSize: { md: '1rem', lg: '1.3rem'}
                  }}
                  onMouseEnter={closeToggels}
                >
                  Giving
                </Button>

                <Button
                  key={'contact'}
                  component={Link} 
                  to={'/contact'}
                  color="inherit" 
                  sx={{ 
                    //my: 2, 
                    //mr: 1, 
                    //ml: 1,
                    m: {md: 0, lg: 1},
                    color: 'white', 
                    display: 'block', 
                    '&:hover': { textDecoration: 'underline' },
                    //fontSize: '17px',
                    fontFamily: 'Merriweather',
                    letterSpacing: '.1rem',
                    fontSize: { md: '1rem', lg: '1.3rem'}
                  }}
                  onMouseEnter={closeToggels}
                >
                  Contact
                </Button>

                
            </Box>
            

            <Box 
              display="flex"
              flexDirection="row"
              justifyContent="right"
              alignItems="center" // Center vertically
              width="25%"
              sx={{ flexGrow: 1 }}
              className={ isHomePage ? (expanded ? 'logo-container-expanded' : 'logo-container-collapsed') : ''}
            >
              <IconButton color="inherit" href="https://www.instagram.com/nazarenebaptist/" target="_blank" >
                <InstagramIcon sx={{ fontSize: { md: '25px', lg: '30px', xl: '30px'}}}/>
              </IconButton>

              <IconButton color="inherit" href="https://www.facebook.com/THENBCMINISTRIES/" target="_blank">
                <FacebookIcon sx={{ fontSize: { md: '25px', lg: '30px', xl: '30px'}}}/>
              </IconButton>

              <IconButton color="inherit" href="https://www.youtube.com/@nazarenebaptistchurch4671" target="_blank">
                <YouTubeIcon sx={{ fontSize: { md: '25px', lg: '35px', xl: '35px'}}} />
              </IconButton>

              {/*<IconButton color="inherit" href="/" target="_blank">
                <LocalSeeIcon sx={{ fontSize: { md: '25px', lg: '35px', xl: '35px'}}} />
              </IconButton>*/}

              <IconButton color="inherit" href="mailto:info@nazarenebaptist.org" target="_blank">
                <EmailIcon sx={{ fontSize: { md: '25px', lg: '30px', xl: '30px'}}}/>
              </IconButton>
            </Box>
 
          </Toolbar>
        
        </Container>
      </AppBar>
    </Box>
    </Grid>
    </Grid>
    }

    {isMobile &&
      <MobileAppBar/>
    }

    </>
  );
}

export default AppAppBar;
