import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography.js';
import Button from '../../components/Button.js';

import ImageMasonry from './PictureTiles.js';

//import conservatory from '../images/aboutImages/conservatory.jpg';
import picture from '../../images/pastorImages/pastorVert.jpg';
import { Masonry } from '@mui/lab';

function MeetSkyyMyles() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#519594', mb: 0 }}
    >
    <Container component="section" sx={{ mt: gridSizeXS ? 0 : 5, mb: gridSizeXS ? 0 : 0 }}>
      <Grid container>
         <Grid item xs={12} sm={12} md={6} lg={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1rem',
              //position: 'absolute',
              //mt: 5,
              zIndex: 1,
            }}
          >
            <Typography 
              variant="h1" 
              align="center" 
              component="h2" 
              gutterBottom 
              sx={{ 
                color: 'white', 
                textDecoration: 'underline',
                fontSize: {
                  xs: '3rem',
                  sm: '3.5rem',
                  md: '3rem',
                  lg: '4rem',
                }, 
              }} 
              >
              His Vision
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,  
                color: 'white', 
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.25rem',
                  md: '1.2rem',
                  lg: '1.25rem',
                },
              }} 
            >
              <span style={{ fontWeight: 'bold' }}>Pastor Alphonzo Davis </span> 
              vision is to lead God's people on to victory, 
              and bring to pass those things that will advance the predestinated plan of God by bring the Body of Christ together in Prayer, 
              Praise and the Word of God. Tobuild and develop a kingdom of empowered people for God, 
              holding firmly to the commissioned mandate in Matthew 28:18-20, "Go ye therefore, and teach all nations", 
              To Know Pastor Davis is to love him, his humbleness, his humor, sincerity, love of people, especially to those of the Household of Faith, and his dedication to the Lord makes him one man of God you will never forget.
            </Typography>
            <Typography variant="subtitle1" 
            sx={{ 
                my: 1,  
                color: 'white', 
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.25rem',
                  md: '1.2rem',
                  lg: '1.25rem',
                },
              }} >
            It is Pastor Davis' prayer that the preaching uttered from his lips be both an 
            <span style={{ fontWeight: 'bold' }}> outreach and an in-reach to all that hear the Word </span>
            and that the spoken Word be filled with compassion, revelation and a true deliverance experience that changes the lives of the masses.
            </Typography>
            <Typography variant="subtitle1" 
              sx={{ 
                my: 1,  
                color: 'white', 
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.25em',
                  md: '1.2rem',
                  lg: '1.25rem',
                },
              }} 
            >
            Pastor Alphonzo M.Davis and his dedicated, committed companion in ministry, Antonia, have six lovely gifts from God, Darryl, Akeylah, Noah, Nylah, Indyah and Ashirah.
            </Typography>
            
          </Box>
        </Grid>


       
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ImageMasonry/>
        </Grid>
        
    
       
      </Grid>
    </Container>
    </Box>
  );
}

export default MeetSkyyMyles;
