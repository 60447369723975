import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

//Images
import pastor1 from '../../images/pastorImages/pastor1.jpg';
import pastor2 from '../../images/pastorImages/pastor2.jpg';
import pastor3 from '../../images/pastorImages/pastor3.jpg';
import pastor4 from '../../images/pastorImages/pastor4.jpg';
import pastorWide from '../../images/pastorImages/pastorWide.jpg';
import pastorSelfie from '../../images/pastorImages/pastorSelfie.jpg';
import pastorKid from '../../images/pastorImages/pastorKid.jpg';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function MasonryImageList() {
  return (
    <Box sx={{ width: {sm: 'auto', md: 500}, height: 1000 }}>
      <ImageList
        sx={{ width: {sm: 'auto', md: 500}, height: 900, ml: {md:0, lg: 5}}}
        variant="quilted"
        cols={4}
        rowHeight={121}
      >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: pastor1,
    //title: 'Bed',
    rows: 3,
    cols: 2,
  },
  {
    img: pastorWide,
    rows: 2,
    cols: 2,
  },
  {
    img: pastor4,
    rows: 3,
    cols: 2,
  },
  {
    img: pastorKid,
    rows: 4,
    cols: 2,
  },
  {
    img: pastor3,
    rows: 2,
    cols: 2,
  },
  
];
