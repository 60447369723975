import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { 
  Grid, 
  Container, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  InputAdornment,
  IconButton,
  Divider,
  FormControlLabel,
  ListItemText,
  Checkbox,
  Box,
  Typography,
  Button,
} from '@mui/material';

//Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';

//Elements
import LocationMap from './Map';

function MapTextLeft() {
  
  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: '#f9f9f9', px: 5, pt: 5, mb: 5 }}
    >
      <Grid container spacing={4} columns={16}>


        {/* Right-side Container */}
        <Grid item xs={16} md={0} lg={0} sx={{ position: 'relative'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //justifyContent: 'center',
              //padding: '2rem',
              position: 'relative',
              alignItems: 'center', // Center text horizontally
              //top: '50%', // Adjust vertical alignment
              //transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
              ml: {xs:0, md:0}
            }}
          >
            <Typography variant="h2" sx={{ color: 'black', mb: {xs:4, md:0}, 
                fontSize: { xs:'3rem' , sm: '4rem', md: '0rem', lg:"0rem"} ,
                textAlign: 'center'
              }}
            >
            Baltimore City
            </Typography>
            
            <Typography variant="h5" align='left' sx={{ color: 'black', mb: {xs:1, md:0}, fontSize: { xs:'1.4rem' , sm: '2rem', md: '0rem', lg:"0rem"} }} >
              <span style={{ fontWeight: 'bold' }}>Service Times:</span> Sun 10 & 11:30
            </Typography>

            <Typography variant="h5" align='left' sx={{ color: 'black', mt: {xs:3, md:0}, fontSize: { xs:'1.4rem' , sm: '2rem', md: '0rem', lg:"0rem"} }} >
              <PlaceIcon sx={{fontSize: {xs: '2.25rem', md: '0rem'}}} style={{ verticalAlign: 'middle', marginRight: '0.5rem' }}/>1201 Harford Ave.
            </Typography>
            <Typography variant="h5" align='left' style={{marginLeft: '5rem' }} sx={{ color: 'black', mb: 1, fontSize: { xs:'1.4rem' , sm: '2rem', md: '0rem', lg:"0rem"} }} >
              Baltimore, MD 21202
            </Typography>

            <Typography variant="h5" align='left' sx={{ color: 'black', mt: {xs:3, md:0}, fontSize: { xs:'1.4rem' , sm: '2rem', md: '0rem', lg:"0rem"} }} >
              <PhoneIcon sx={{fontSize: {xs: '2.25rem', md: '0rem'}}} style={{ verticalAlign: 'middle', marginRight: '0.2rem' }}/> (410) 385 - 1986
            </Typography>
            <Typography variant="h5" align='left' sx={{ color: 'black', mt: {xs:3, md:0}, fontSize: { xs:'1.4rem' , sm: '2rem', md: '0rem', lg:"0rem"} }} >
              <EmailIcon style={{ verticalAlign: 'middle', marginRight: '0.4rem' }} sx={{fontSize: {xs: '2.25rem', md: '0rem'}}}/> info@nazarenebaptist.org
            </Typography>

            <Box 
              
              //display="flex"
              flexDirection="row"
              //justifyContent="right"
              //alignItems="center" // Center vertically
              //width="25%"
              sx={{ flexGrow: 1, mt: {xs:4, md:0} }}
              //className={ isHomePage ? (expanded ? 'logo-container-expanded' : 'logo-container-collapsed') : ''}
            >
              <IconButton color="inherit" href="https://www.instagram.com/nazarenebaptist/" target="_blank" >
                <InstagramIcon  sx={{ fontSize: {xs: '45px', md: '0px'}, }}/>
              </IconButton>

              <IconButton color="inherit" href="https://www.facebook.com/THENBCMINISTRIES/" target="_blank">
                <FacebookIcon sx={{ fontSize: {xs: '45px', md: '0px'}}}/>
              </IconButton>

              <IconButton color="inherit" href="https://www.youtube.com/@nazarenebaptistchurch4671" target="_blank">
                <YouTubeIcon sx={{fontSize: {xs: '50px', md: '0px'}}} />
              </IconButton>

              <IconButton color="inherit" href="/" target="_blank">
                <LocalSeeIcon sx={{ fontSize: {xs: '45px', md: '0px'}}} />
              </IconButton>

            </Box>

          </Box>
        </Grid>


        {/* Left Side Map*/}
        <Grid item xs={16} md={8} align="center" sx={{ mt: {xs: 0, md: -5}}}>
          <LocationMap/>
        </Grid>


        {/* Right-side Container */}
        <Grid item xs={16} md={8} sx={{ position: 'relative', mt: {xs: -10, md: 0}, mb: {xs: -10, md: 0} }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //justifyContent: 'center',
              //padding: '2rem',
              position: 'relative',
              //alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
              ml: 5,
            }}
          >
            <Typography variant="h2" align='left' sx={{ color: 'black', mb: 4, fontSize: { xs:'0rem' , sm: '0rem', md: '3rem', lg:"3.75rem"} }} >
            Baltimore City
            </Typography>
            
            <Typography variant="h5" align='left' sx={{ color: 'black', mb: 1, fontSize: { xs:'0rem' , sm: '0rem', md: '1.5rem', lg:"1.75rem"} }} >
              <span style={{ fontWeight: 'bold' }}>Service Times:</span> Sun 10 & 11:30
            </Typography>

            <Typography variant="h5" align='left' sx={{ color: 'black', mt: 3, fontSize: { xs:'0rem' , sm: '0rem', md: '1.5rem', lg:"1.75rem"} }} >
              <PlaceIcon sx={{fontSize: {xs: '0rem', md: '2.25rem'}}} style={{ verticalAlign: 'middle', marginRight: '0.5rem' }}/>1201 Harford Ave.
            </Typography>
            <Typography variant="h5" align='left' style={{marginLeft: '2.75rem' }} sx={{ color: 'black', mb: 1, fontSize: { xs:'0rem' , sm: '0rem', md: '1.5rem', lg:"1.75rem"} }} >
              Baltimore, MD 21202
            </Typography>

            <Typography variant="h5" align='left' sx={{ color: 'black', mt: 3, fontSize: { xs:'0rem' , sm: '0rem', md: '1.5rem', lg:"1.75rem"} }} >
              <PhoneIcon sx={{fontSize: {xs: '0rem', md: '2.25rem'}}} style={{ verticalAlign: 'middle', marginRight: '0.2rem' }}/> (410) 385 - 1986
            </Typography>
            <Typography variant="h5" align='left' sx={{ color: 'black', mt: 3, fontSize: { xs:'0rem' , sm: '0rem', md: '1.5rem', lg:"1.75rem"} }} >
              <EmailIcon sx={{fontSize: {xs: '0rem', md: '2.25rem'}}} style={{ verticalAlign: 'middle', marginRight: '0.4rem' }}/> info@nazarenebaptist.org
            </Typography>

            <Box 
              
              //display="flex"
              flexDirection="row"
              //justifyContent="right"
              //alignItems="center" // Center vertically
              //width="25%"
              sx={{ flexGrow: 1, mt: 4 }}
              //className={ isHomePage ? (expanded ? 'logo-container-expanded' : 'logo-container-collapsed') : ''}
            >
              <IconButton color="inherit" href="https://www.instagram.com/nazarenebaptist/" target="_blank" >
                <InstagramIcon  sx={{ fontSize: {xs: '0px', md: '45px'}}}/>
              </IconButton>

              <IconButton color="inherit" href="https://www.facebook.com/THENBCMINISTRIES/" target="_blank">
                <FacebookIcon sx={{ fontSize: {xs: '0px', md: '45px'}}}/>
              </IconButton>

              <IconButton color="inherit" href="https://www.youtube.com/@nazarenebaptistchurch4671" target="_blank">
                <YouTubeIcon sx={{ fontSize: {xs: '0px', md: '50px'}}} />
              </IconButton>

              <IconButton color="inherit" href="/" target="_blank">
                <LocalSeeIcon sx={{ fontSize: {xs: '0px', md: '45px'}}} />
              </IconButton>

            </Box>

          </Box>
        </Grid>

        
      </Grid>
    </Box>
  );
}

export default MapTextLeft;
